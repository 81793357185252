/*
包含n个mutation的type名称常量
 */
export const TOKEN = 'token' // 用户登录
export const HGPRICE = 'hgprice' // 护工价格
export const HGINFO = 'hginfo' // 护工信息
export const PATIETINFO = 'patientinfo' // 选中患者的信息
// export const MOBILE_TENEMENT_ID = 'mobile_tenement_id' // 用户手机号和租户id





