import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import { IndexBar, IndexAnchor, Sticky, Dialog, Popup, Picker } from 'vant';
import 'vant/lib/dialog/style';
import 'vant/lib/popup/style';
import 'vant/lib/picker/style';
Vue.use(IndexBar)
  .use(IndexAnchor)
  .use(Sticky)
  .use(Popup)
  .use(Picker)
  .use(Dialog);

import 'amfe-flexible/index.js'

import './api/ajax'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
