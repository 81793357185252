<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  components: {
  },
  created() {

  },
  methods: {

  },
  watch: {

  },
};
</script>
<style >
html,
body,
#app {
  font-family: -apple-system, "Helvetica Neue", "Roboto", sans-serif;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #efeff4;
  overflow-x: hidden;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 500ms;
  position: absolute;
}

.slide-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-right-leave-active {
  opacity: 0;
  transform: translateX(100%);
}

.slide-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.slide-left-leave-active {
  opacity: 0;
  transform: translateX(-100%);
}
</style>
