import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home/home.vue'
import mobile from '../views/mobile/mobile.vue'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    component: home,
    meta: { index: 1, showFooter: true }
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: mobile,
    meta: { index: 1, showFooter: true }
  },
]




const router = new VueRouter({
  routes
})

//路由导航守卫
// router.beforeEach((to, from, next) => {
//   //to 要去的路由配置
//   //from 当前的路由配置
//   //next 一定要调用，让to的路由配置继续生效，比如如果去登陆直接next(),否则判断token是否存在，如果存在就next()

//   if (to.path === '/login') return next() ;//使用return，不需要写else

//   //判断其他页面是否有token
//   const token = localStorage.getItem('token');

//   //存在继续往后走
//   if (token) return next();


//   // this.$router.push({name:'login'}) #没有this,无法使用
//   router.push('/login')



// });

export default router
