<template>
  <transition name="toast-fade">
    <div v-if="visible" class="toast">
      {{ message }}
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      message: '',
      timer: null
    };
  },
  methods: {
    show(msg, duration = 2000) {
      this.message = msg;
      this.visible = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.visible = false;
      }, duration);
    }
  }
};
</script>

<style>
.toast {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
  line-height: 15px;
  text-align: center;
}

.toast-fade-enter-active,
.toast-fade-leave-active {
  transition: opacity 0.5s;
}

.toast-fade-enter,
.toast-fade-leave-to {
  opacity: 0;
}
</style>
