/*
包含n个接口请求函数的模块
函数的返回值: promise对象
 */
import { get, post, put } from './ajax'


export const getVfqClinetData = parameters => post('/api/client/getVfqClinetData.do?companyCode=' + parameters.companyCode) // 登录接口
// export const service_info = () => get('/nursing/service_info') // 服务包信息
// export const nursing_worker = parameters => get('/nursing/nursing_worker',parameters) // 护工列表
// export const nursing_worker_info = parameters => get('/nursing/nursing_worker/'+parameters) /// 护工详情
// export const patient_info = () => get('/nursing/patient_info') /// 获取就诊人信息
// export const postUploadImage = (parameters,options) => post('/api/uploadImage',parameters,options) /// 图片上传
// export const postorderinfo = parameters => post('/nursing/nursing_order',parameters) /// 图片上传
// export const nursing_order = () => get('/nursing/nursing_order?type=1') // 获取所有订单
// export const order_pay = parameters => put('/nursing/nursing_order/1',parameters) // 支付
// export const api2 = p2 => get('https://xxx/v5/weather?city=taian&key=1b47b16e4aa545eaa55a66f859ac0089', p2)
// export const api3 = p => post('https://xxx/svserver/upload/', p)
