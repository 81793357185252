<template>
    <transition @before-enter="beforeEnter" @enter="enter" @leave="leave">
      <div v-if="visible" class="modal-overlay">
        <div class="modal-content">
          <slot></slot>
          <div style="width: 100%;text-align: center;padding: 20px;cursor: pointer;border-top: 1px solid rgb(216 213 213)" @click="close">关闭</div>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    props: {
      visible: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      close() {
        this.$emit('update:visible', false);
      },
      beforeEnter(el) {
        el.style.opacity = 0;
        el.style.transform = 'translateY(-30px)';
      },
      enter(el, done) {
        el.offsetHeight; // trigger reflow
        el.style.transition = 'opacity 0.3s ease, transform 0.3s ease';
        el.style.opacity = 1;
        el.style.transform = 'translateY(0)';
        done();
      },
      leave(el, done) {
        el.style.transition = 'opacity 0.3s ease, transform 0.3s ease';
        el.style.opacity = 0;
        el.style.transform = 'translateY(-30px)';
        setTimeout(() => {
          done();
        }, 300); // 确保动画完成后才调用 done()
      }
    }
  }
  </script>
  
  <style>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  .modal-content {
    background: white;
    border-radius: 5px;
  }
  </style>
  