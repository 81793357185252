<template>
  <div class="iframebox" v-if="ispc">
    <iframe id="report-iframe" :src="curURL" frameborder="0" style="width:400px; height: 100vh;"></iframe>
  </div>
</template>

<script>

export default {
  name: "home",
  data() {
    return {
      ispc: false,
      curURL: ''
    };
  },

  created() {
    let curURL = window.location.href;
    this.curURL = curURL.replace("/home", "/mobile");

    var sUserAgent = navigator.userAgent.toLowerCase();

    var bIsIpad = sUserAgent.match(/ipad/i) == 'ipad';
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == 'iphone os';
    var bIsMidp = sUserAgent.match(/midp/i) == 'midp';
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4';
    var bIsUc = sUserAgent.match(/ucweb/i) == 'ucweb';
    var bIsAndroid = sUserAgent.match(/android/i) == 'android';
    var bIsCE = sUserAgent.match(/windows ce/i) == 'windows ce';
    var bIsWM = sUserAgent.match(/windows mobile/i) == 'windows mobile';

    if (
      bIsIpad ||
      bIsIphoneOs ||
      bIsMidp ||
      bIsUc7 ||
      bIsUc ||
      bIsAndroid ||
      bIsCE ||
      bIsWM
    ) {
      this.$router.replace({ path: '/mobile?companyCode=' + curURL.split('=')[1] })

    } else {
      this.ispc = true;

    }
    // this.getVfqClinetDatafn()
  }

};
</script>
<style>
.iframebox {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
</style>